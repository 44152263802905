@import "../../../variables.scss";
.assetTableTools {
    @include fixed-height(72px);
    @include fixed-width(844px);
    padding-top: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .sortBySelect.sortBySelect {
        @include valeo-select-size(192px, 56px);
        @include valeo-select-non-empty-notch(48.5px);
        @include valeo-select-selected-text(400, 16px, 40px, 0.44px);
        @include valeo-select-option-text(400, 16px, 40px, 0.44px);
        margin-right: 24px;
    }

    .checkbox {
        margin-left: 13px;
        font-size: 16px;
    }

    button:global(.mdc-button) {
        color: $valeo_blue;
    }

    .buttons {
        margin-left: 24px;
    }
}