@import "../../../../variables.scss";

.checkboxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    @include fixed-height(100%);
}

.checkbox {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $valeo_black;
    margin-right: 24px;
}

.emptyPage {
    height: 580px;

    .progress {
        color: $valeo_blue;
        @include fixed-height(12px);

        :global(.mdc-linear-progress__bar-inner) {
            border-top: 12px solid;
        }
    }
}