@import "../../../variables.scss";

.elevation {
    position: relative;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);

    .controlsContainer {
        padding-left: 44px;
        display: flex;
        background-color: white;

        .leftControls {
            padding-top: 24px;
            @include fixed-size(614px, 80px);

            .leftControlsTopLevel {
                display: inline-flex;
                align-items: center;
                
                .targetUnitsButton {
                    height: 28px;
                    @include fixed-width(28px);
                }

                .targetUnitsButtonTop {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .targetUnitsButtonBottom {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }

                .selectedUnitsButton {
                    background-color: $valeo_blue;
                    color: white;
                }

                .targetUnitsButtons {
                    margin-left: 16px;
                    margin-right: 0px;
                    display: flex;
                    flex-direction: column;
                }
            }

            .leftControlsHelpText {
                font-size: 12px;
                color: $valeo_black;
                font-weight: 400;
                white-space: nowrap;
                letter-spacing: 0.4px;
            }

            .cashTargetHelper {
                margin-left: 324px;
            }
        }

        .carveouts {
            padding-top: 24px;
            padding-right: 44px;
            margin-left: 26px;
            padding-left: 44px;
            background-color: $valeo_gray_20;
            clip-path: polygon(0% 50%, 3.1% 36.7%, 3.1% 0%, 100% 0%, 100% 100%, 3.1% 100%, 3.1% 63.3%);

            .rightControls {
                display: inline-flex;
            }

            .helpText {
                color: $valeo_black;
                font-size: 12px;
                float: right;
                font-weight: 400;
                letter-spacing: 0.4px;
            }
        }

        .cashTarget {
            width: 136px;
            margin-left: 4px;

            @include valeo-text-field-non-empty-notch(73px);

            :global(.mdc-text-field__input) {
                font-size: 16px;
            }

            :global(.mdc-text-field__affix) {
                padding-left: 0;
            }
        }

        .alternativesTarget {
            width: 152px;
            margin-left: 4px;

            @include valeo-text-field-non-empty-notch(112px);

            :global(.mdc-text-field__input) {
                font-size: 16px;
            }

            :global(.mdc-text-field__affix) {
                padding-left: 0;
            }
        }

        .percentageEntry:global(.mdc-text-field) {
            width: 142px;
            margin-left: 16px;
            padding-right: 8px;

            :global(.mdc-text-field__input) {
                font-size: 16px;
            }

            &.cashTarget {
                width: 136px;
                margin-left: 4px;

                @include valeo-text-field-non-empty-notch(73px);
            }

            &.alternativesTarget {
                width: 152px;
                margin-left: 4px;
                @include valeo-text-field-non-empty-notch(112px);
            }

            &.cashCarveOut {
                @include valeo-text-field-non-empty-notch(68px);
            }

            &.fixedIncomeCarveOut {
                @include valeo-text-field-non-empty-notch(112px);
            }

            &.equitiesCarveOut {
                @include valeo-text-field-non-empty-notch(83px);
            }
        }
    }
}

