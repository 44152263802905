@import "../../variables.scss";

.dialog {
    overflow: auto;

    :global(.mdc-dialog__container) {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 0;
        height: fit-content;
        padding-left: calc(100vw - 100%);
    }

    :global(.mdc-dialog__surface) {
        @include fixed-size(fit-content, fit-content);
        overflow: visible;
    }

    :global(.mdc-dialog__content) {
        padding: 0;
        overflow: visible;
    }

    button:global(.mdc-button).modalButton {
        border-radius: 2px;
        font-size: 14px;
        color: white;
        margin-top: auto;
        margin-bottom: auto;

        &.cancelButton {
            padding-top: 13px;
        }

        &.disabledButton {
            background-color: $valeo_black_10;

            :global(.mdc-button__label) {
                color: $valeo_black_30;
            }
        }
    }

    .dialogTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include fixed-height(47px);
        width: 100%;
        padding: 0 44px 0 44px;
        border-radius: 4px 4px 0 0;
        background-color: $valeo_blue;

        .title {
            position: absolute;
            font-family: 'Zilla Slab';
            color: white;
            font-size: 24px;
        }
    }

    .dialogActions {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        @include fixed-height(52px);
        border: none;
        padding: 0 44px 0 44px;
        border-radius: 0 0 4px 4px;
        color: $valeo_blue;

        .proceedButton {
            @include fixed-height(36px);
            background-color: $valeo_blue;
        }
    }
}