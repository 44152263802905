@import "../../../variables.scss";

.downloadFidelityTradesButton {
    @include fixed-width(288px);
    display: flex;
    justify-content: space-between;

    i {
        color: $gray;
    }
}

.sendToFidelityButton {
    @include fixed-width(200px);
    display: flex;
    justify-content: space-between;

    margin-top: 16px;

    i {
        margin-left: 0;
        color: $gray;
    }
}

.sentToFidelityLabel {
    color: $valeo_black;
    font-family: "Outfit";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    margin-right: 18px;
    margin-top: 26px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.absentButtonFiller {
    @include fixed-height(16px);
}

.redWarning {
    color: $dark_red;
}