@import "../../../../../variables.scss";

.portfoliosTable {

    :global(.mdc-data-table) {
        @include fixed-width(100%);
        @include remove-table-border();
    }

    .noPortfolioRow {
        border-width: 0px;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: $valeo_black_10;

        &:hover {
            background-color: transparent;
        }

        td {
            display: inline-flex;
            align-items: center;
            @include fixed-height(48px);
            padding: 0;
        }

        .chevron {
            @include fixed-width(40px);
        }

        .radio {

            :global(.mdc-radio) {
                padding: 10px 22px 10px 14px;

                :global(.mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle),
                :global(.mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle) {
                    border-color: $valeo_blue;
                }
            }
        }
    }
}