@import "../../variables.scss";

.topbar {
    background-color: $valeo_blue;
    color: white;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    min-width: $pageWidth;
    width: 100%;
    max-width: 100vw;
    padding-right: 44px;

    .logoLink {
        margin-left: 40px;
        margin-right: 12px;
        margin-top: 20px;
        margin-bottom: 16px;
    }

    .logo {
        width: 24px;
        height: 24px;
    }

    .clientName {
        flex-grow: 1;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hamburger {
        margin-left: 8px;
    }

    .userContainer {
        font-size: 20px;
        letter-spacing: 0.15px;
        display: flex;
        align-items: center;

        .userIcon {
            margin-right: 12px;
        }

        .userName {
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .clientLink {
        text-decoration: none;
        color: inherit;
    }
}