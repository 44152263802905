@import "../../variables.scss";

.notesPreview {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: auto;
    margin-bottom: auto;
    -webkit-line-clamp: 5;
    overflow-wrap: anywhere;
}
