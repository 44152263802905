@import "../../variables.scss";

.linearStepper {
    display: inline-flex;
    .step {
        display: inline-flex;
        align-items: center;
        .stepNumber {
            @include fixed-size(24px, 24px);
            color: white;
            background-color: $valeo_black_30;
            border-radius: 500px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .stepName {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.1px;
            margin-left: 8px;
            color: $valeo_black_60;
        }
    }

    .currentStep {
        .stepNumber {
            background-color: $valeo_blue;
        }

        .stepName {
            color: $valeo_black;
        }
    }

    .passedStep {
        .stepNumber {
            background-color: $valeo_blue;
        }
    }

    .connector {
        @include fixed-size(40px, 11px);
        margin-left: 64px;
        margin-right: 64px;

        border-bottom: 1px solid #BDBDBD;
        translate: 0px -6px;

        &.fiveSteps {
            margin-left: 22px;
            margin-right: 22px;
        }
    }
}