@import "../../../../variables.scss";

button:global(.mdc-button).button {
    @include fixed-height(36px);
    border-radius: 2px;
    font-size: 14px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;

    &.enabled {
        background-color: $valeo_blue;
    }

    &.disabled {
        background-color: $valeo_black_10;

        :global(.mdc-button__label) {
            color: $valeo_black_30;
        }
    }
}