@import "../../../variables.scss";

.changeTextField {
    @include fixed-size(112px, 40px);

    :global(.mdc-text-field__input) {
        font-size: 14px;
        font-weight: 700;
    }

    &.wide {
        @include fixed-size(120px, 40px);
    }
}

.excessSellSnackbar :global(.mdc-snackbar__surface) {
    @include fixed-width($pageWidth);
    background-color: white;
    border: 2px solid $valeo_blue;
    :global(.mdc-snackbar__label) {
      color: $valeo_blue;
    }

    :global(.material-icons) {
        color: $valeo_black_60;
    }

    text-align: left;

    :global(.mdc-snackbar__actions) {
        @include fixed-width(84px);
        justify-content: flex-end;
    }

    button {
        color: $valeo_blue;
        width: inherit;
    }
  }