@import "../../variables";

.background {
    min-width: fit-content;
    min-height: 100vh;

    .contentContainer {
        background-image: url('../../../public/background-tile.svg');
        // The height needs to be the entire viewpoint height minus the height of the topbar.
        min-height: calc(100vh - $clientProfileTopbarHeight);
    }

    .content {
        display: grid;
        @include fixed-width($pageWidth);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding-top: 12px;
    }
}