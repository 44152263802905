.depth0 {
    padding-left: 0px;
}

.depth1 {
    padding-left: 44px;
}

.depth2 {
    padding-left: 88px;
}

.depth3 {
    padding-left: 132px;
}