@import "../../variables.scss";

.textField:global(.mdc-text-field) {
    background-color: white;
    border: 1px solid;
    border-radius: 4px;
    border-color: $valeo_black_30;

    :global(.mdc-floating-label) {
        color: $valeo_black_30;
    }

    :global(.mdc-text-field__input) {
        color: black;
    }

    span:before {
        background-color: white;
    }

    :global(.mdc-line-ripple) {
        visibility: hidden;
    }
}

.textField:global(.mdc-text-field:not(.mdc-text-field--disabled)):hover {
    border-color: $valeo_black_60;
}

.textField:global(.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused) {
    border-color: $valeo_blue;

    :global(.mdc-floating-label) {
        color: $valeo_blue;
    }
}

.textField:global(.mdc-text-field--disabled) {
    border-color: $valeo_black_30;

    :global(.mdc-floating-label) {
        color: $valeo_black_30;
    }
}

.textField:global(.mdc-text-field--label-floating) {
    :global(.mdc-floating-label) {
        color: $valeo_black_60;
    }

    border-color: $valeo_black_60;
}