@import "../../../../../variables.scss";

.helpText {
    font-size: 12px;
    color: $valeo_black;
    padding-right: 16px;
}

.nextButton:global(.mdc-dialog__button.mdc-button) {
    margin-top: auto;
}

.connectionFailure {
    line-height: 25.2px;
    letter-spacing: 0.44px;
}

.redText {
    color: $dark_red;
}