@import "../../variables.scss";

.dialogContent {
    padding-left: 44px;
    padding-top: 24px;

    .header.header {
        color: $valeo_black;
        padding-top: 24px;
    }

    .selectButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        @include fixed-size(100%, 60px);

        button {
            margin: 0;

            &.deselectAllButton {
                margin-left: 12px;
            }
        }
    }
    
    .statusList {
        display: flex;
        flex-direction: row;

        .column {
            display: flex;
            flex-direction: column;
            width: 160px;
            margin-right: 44px;

            .statusCheckbox {
                color: $valeo_black;
                padding-top: 16px;
                font-size: 16px;
            }
        }
    }
}