@import "../../variables.scss";

.row {
    .meetingCell {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-weight: 500;
        overflow: visible;
    }

    .kebabCell {
        padding-right: 8px;

        button {
            margin-left: auto;
        }

        .kebab {
            display: none;
        }

        .visible {
            display: flex;
        }
    }

    .menu {
        width: max-content;
        translate: 15px;

        .menuItem {
            color: $valeo_black_90;
            font-size: 14px;
        }
    }

}

.row:hover .kebab {
    display: flex;
}

.dialog {
    cursor: auto;
}