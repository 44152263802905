@import "../../variables.scss";

.numericTextField {
    :global(.mdc-text-field__input) {
        font-size: 14px;
    }

    :global(.mdc-text-field__affix) {
        padding-left: 4px;
    }

    :global(.mdc-text-field__icon--leading) {
        margin-left: 8px;
    }
}

.numericTextField.negative:global(:not(.mdc-text-field--focused)) :global(.mdc-text-field__input),
.numericTextField.negative:global(:not(.mdc-text-field--invalid)):not(.mdc-text-field--disabled) :global(.mdc-text-field__input) {
    color: $dark_red;
}