@import "../../../variables.scss";

.headerText {
    height: 40px;
    padding-top: 24px;

    span {
        line-height: 24px;
    }
}

.leftPad32 {
    padding-left: 32px;
}


.nameCard {
    padding-bottom: 16px;

    :global(.mdc-card) {
        @include fixed-height(104px);
    }
}

.modelPortfolioCard {
    :global(.mdc-card) {
        @include fixed-height(76px);
    }
}

.reportTitleCard {
    padding-bottom: 16px;

    :global(.mdc-card) {
        @include fixed-height(104px);
    }
}

.rightGroup {
    display: grid;
    padding-right: 72px;
    justify-items: right;
    height: fit-content;

    grid-template-rows: min-content;

    .final {
        text-transform: uppercase;
        padding-right: 12px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
    }

    .whbutton {
        @include fixed-width(180px);
        display: flex;
        justify-content: space-between;

        margin-top: 16px;
        padding: 0 12px 0 8px; // override site global default for .mdc-button

        i {
            color: $gray;
        }
    }

    .showAltTargets {
        @include fixed-size(172px, 36px);
        font-size: 16px;
        font-weight: 400;
        color: $valeo_black_90;

        padding-right: 12px;

        label {
            padding-left: 0px;
        }
    }

    :global(.mdc-button) {
        color: $valeo_blue;
    }

    .importDate {
        padding-right: 12px;
        height: 20px;
        display: flex;
        align-items: center;
        white-space: pre;

        span {
            line-height: 20px;
        }
    }

    .selectMenu {
        @include valeo-select-size(188px, 56px);
        @include valeo-select-non-empty-notch(40.25px);
        @include valeo-select-selected-text(500, 14px, 16px, 1px);
        @include valeo-select-option-text(500, 14px, 40px, 1px);
        @include valeo-select-uppercase();
    }
}

.flex {
    display: flex;
    justify-content: space-between;
    @include fixed-height(336px);

    .leftGroup {
        display: flex;
    }
}

.notesCardContainer {
    padding-left: 32px;
}