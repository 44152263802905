@import "../../../variables.scss";

.table {
    @include fixed-width(100%);
    border: 0px;
    @include table-row-transparent-hover();

    .icon {
        color: $gray;
        margin-left: 5px;
    }

    :global(.rmwc-data-table__row) {
        @include fixed-height(47px);
        border-color: $valeo_black_10;
        border-top-width: 0px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    :global(.rmwc-data-table__cell) {
        display: inline-flex;
        align-items: center;
        @include fixed-width(300px);
        @include fixed-height(47px);
        padding: 0 12px 0 11px;
    }

    :global(.rmwc-data-table__cell:first-child) {
        @include fixed-width(284px);
        margin-left: 33px;

        .entryName {
            @include fixed-width(152px);
        }
    }

    :global(.rmwc-data-table__cell:not(:last-child)) {
        border-color: $valeo_black_10;
        border-right-width: 1px;
        border-right-style: solid;
    }

    :global(.rmwc-data-table__cell:last-child) {
        @include fixed-width(299px);
    }

    :global(.rmwc-data-table__cell.mdc-data-table__header-cell) {
        color: $valeo_black;
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    :global(.rmwc-data-table__cell.mdc-data-table__cell) {
        color: $valeo_black;
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 500;
    }

    .kebabCell:global(.mdc-data-table__cell) {
        overflow: visible;

        :global(.mdc-icon-button) {
            color: $gray;
            margin-left: auto;
            display: none;
        }

        &:hover {
            :global(.mdc-icon-button) {
                display: flex;
            }
        }
    }

    .targetHeaderCell {
        justify-content: right;
    }

    .portfolioAssumptionCell:global(.mdc-data-table__cell) {
        @include fixed-width(100%);
        justify-content: center;
        margin-left: 0;
        // padding-left: 44px;
        background-color: $valeo_gray_20;

        .totalPortfolioValueTextField {
            @include fixed-size(124px, 40px);
            margin-left: 12px;

            :global(.mdc-text-field__affix) {
                padding-left: 0;
            }
        }
    }

    .entryName {
        @include fixed-width(168px);
        margin-right: 24px;
        text-transform: none;

        &.allowCustomName {
            margin-right: 0;
        }

        .overflowClamped {
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-top: auto;
            margin-bottom: auto;
            -webkit-line-clamp: 2;
        }
    }

    .customNameTextField {
        @include fixed-size(168px, 40px);
    }

    .customNameKebab {
        display: flex;
        justify-content: center;
        @include fixed-width(24px);
    }

    .uppercase {
        text-transform: uppercase;
    }

    .entryTarget {
        @include fixed-width(84px);
        text-align: right;
    }
}