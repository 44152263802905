@import "../../../variables.scss";

.select {
    @include valeo-select-size(240px, 56px);
    @include valeo-select-selected-text(400, 16px, 40px, 0.44px);
    @include valeo-select-option-text(400, 16px, 40px, 0.44px);

    &.nonEmptyName {
        @include valeo-select-non-empty-notch(55.25px);
    }

    .customOption {
        font-weight: 600;
    }
}