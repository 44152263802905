@import "../../../../variables.scss";

.content {
    display: flex;
    @include fixed-size(100%, 580px);
    justify-content: center;
    align-items: center;

    .dateAndName {
        display: inline-flex;

        .nameTextField {
            @include fixed-width(392px);
            margin-left: 158px;
            @include valeo-text-field-text(400, 16px, 20px, 0.44px);
        }
    }
}
