@import "../../../../../variables.scss";

.blurred {
    position: absolute;
    top: 0;
    padding-top: 47px;
    @include fixed-size(100%, calc(100% - 99px));
}

.blur {
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    z-index: 500;
    border-radius: 4px;
    @include fixed-size(100%, 100%);
}

.helpTextContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    @include fixed-height(100%);

    .helpText {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-right: 24px;
        color: $valeo_black;
    }
}