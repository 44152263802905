@import "../../../variables.scss";

.asset_table {
    height: fit-content;
    margin-right: 16px;
    margin-bottom: 16px;
    border-width: 0px;
    border-collapse: collapse;

    td, th {
        height: 47px;
    }

    .total {
        font-size: 16px;
        font-weight: 500;
    }

    .bold {
        font-weight: 700;
    }

    th {
        color: $valeo_black;
        height: 47px;
    }

    td {
        white-space: normal;
    }

    .assetTableHeader {
        display: flex;
        align-items: center;
        text-transform: none;
    }

    button:not(:global(.mdc-dialog__surface button)) {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    tbody {
        tr {
            border-bottom: 1px solid lightgray;
        }
    }

    .green {
        background-color: $valeo_green_80;
    }

    .red {
        background-color: $valeo_red_orange_80;
    }

    .blue {
        background-color: $valeo_blue_80;
    }

    .yellow {
        background-color: $valeo_yellow_80;
    }

    .gray {
        background-color: $valeo_gray_80;
    }

    .light_gray {
        background-color: $valeo_gray_20;
    }

    .green_value {
        color: $valeo_green_130;
    }

    .red_value {
        color: $valeo_red_orange_130;
    }

    .blue_value {
        color: $valeo_blue_130;
    }

    .yellow_value {
        color: $valeo_yellow_130;
    }

    td:nth-of-type(2), th:nth-of-type(2) {
        padding: 0px;
    }

    td:nth-of-type(3), th:nth-of-type(3) {
        padding-left: 0px;
        padding-right: 0px;
    }

    td:nth-of-type(4), th:nth-of-type(4) {
        padding-left: 8px;
        padding-right: 0px;
    }

    td:nth-of-type(5), th:nth-of-type(5) {
        padding-left: 8px;
        padding-right: 0px;
    }

    td:nth-of-type(6), th:nth-of-type(6) {
        padding-left: 8px;
        overflow: visible;
    }
}

.narrow {
    @include fixed-width($tableMinWidth);

    tr:global(.fiveColumns) {
        td:nth-of-type(1), th:nth-of-type(1) {
            @include fixed-width(368px);
            padding-right: 0px;
        }
        td:nth-of-type(2), th:nth-of-type(2) {
            @include fixed-width(112px);
        }
        td:nth-of-type(3), th:nth-of-type(4) {
            @include fixed-width(140px);
        }
        td:nth-of-type(4), th:nth-of-type(5) {
            @include fixed-width(108px);
        }
        td:nth-of-type(5), th:nth-of-type(6) {
            @include fixed-width(116px);
            padding-right: 8px;
        }
    }

    tr:not(:global(.fiveColumns)) {
        td:nth-of-type(1), th:nth-of-type(1) {
            @include fixed-width(368px);
            padding-right: 0px;
        }
        td:nth-of-type(2), th:nth-of-type(2) {
            @include fixed-width(32px);
        }
        td:nth-of-type(3), th:nth-of-type(3) {
            @include fixed-width(80px);
        }
        td:nth-of-type(4), th:nth-of-type(4) {
            @include fixed-width(140px);
        }
        td:nth-of-type(5), th:nth-of-type(5) {
            @include fixed-width(108px);
        }
        td:nth-of-type(6), th:nth-of-type(6) {
            @include fixed-width(116px);
            padding-right: 8px;
        }
    }

    tr:not(:global(.fiveColumns)).totals_row {
        td:nth-of-type(1) {
            @include fixed-width(480px);
            padding-right: 0px;
        }
        td:nth-of-type(2) {
            @include fixed-width(140px);
        }
        td:nth-of-type(3) {
            @include fixed-width(108px);
        }
        td:nth-of-type(4) {
            @include fixed-width(116px);
            padding-right: 8px;
        }
    }
}

.current_totals_content {
    // In the totals row, the content of the current value needs to expand to fill the space
    // reserved for the tooltip column
    margin-left: -20px;
}

.wide {
    @include fixed-width($tableMaxWidth);

    td:nth-of-type(1), th:nth-of-type(1) {
        @include fixed-width(536px);
    }
    td:nth-of-type(2), th:nth-of-type(2) {
        @include fixed-width(32px);
    }
    td:nth-of-type(3), th:nth-of-type(3) {
        @include fixed-width(120px);
    }
    td:nth-of-type(4), th:nth-of-type(4) {
        @include fixed-width(176px);
    }
    td:nth-of-type(5), th:nth-of-type(5) {
        @include fixed-width(176px);
    }
    td:nth-of-type(6), th:nth-of-type(6) {
        @include fixed-width(208px);
        padding-right: 28px;
    }

    tr.totals_row {
        td:nth-of-type(1) {
            @include fixed-width(536px);
            padding-right: 0px;
        }
        td:nth-of-type(2) {
            @include fixed-width(176px);
        }
        td:nth-of-type(3) {
            @include fixed-width(176px);
        }
        td:nth-of-type(4) {
            @include fixed-width(208px);
            padding-right: 28px;
        }
    }
}

.expansionButton {
    color: $valeo_black;
}

.totalsTable {
    th {
        overflow: visible;
        white-space: normal;
    }
}