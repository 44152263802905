@import "../../variables.scss";

.selectDiv {
    width: fit-content;

    .select {
        @include valeo-select-size(216px, 56px);
        @include valeo-select-selected-text(400, 16px, 20px, 0.44px);
        @include valeo-select-option-subheader-text(400, 16px, 24px, 0.44px);
        @include valeo-select-option-text(400, 16px, 20px, 0.44px);

        :global(.mdc-select__selected-text) {
            @include fixed-height(20px);
            white-space: pre;
        }

        &.fixBorderOverFloatingLabelBug {
            @include valeo-select-non-empty-notch(116px);
        }

        &.includesBlended {
            :global(.mdc-list-group):last-child {
                :global(.mdc-list) {
                    :global(.mdc-list-item) {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            .blendedOption {
                padding-bottom: 15px;

                .blendedOptionHeader {
                    padding: 8px 0 1px 0;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }

                .blendedOptionHelper {
                    font-size: 10px;
                    line-height: 10px;
                    letter-spacing: 0px;
                }
            }
        }

        :global(.mdc-select__menu) {
            :global(.mdc-list-group__subheader) {
                // override RMWC: use padding instead of margin to keep group header within group and below divider
                margin-top: 0px;
                margin-bottom: 0px;
                padding-top: 8px;
                padding-bottom: 8px;

                span {
                    color: $valeo_black_60;
                }
            }

            @include valeo-select-text-wrap();

            :global(.mdc-list.mdc-menu__items) {
                padding-top: 0;
                padding-bottom: 0;

            }

            :global(.mdc-list-divider) {
                margin-top: 11px;
                margin-bottom: 12px;
            }
        }
    }

    .tooltipDiv {
        margin-left: auto;
        margin-right: auto;
    }
}

:global(.rmwc-tooltip-inner) {
    font-size: 14px;
}