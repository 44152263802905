@import "../../../variables.scss";

.title,
.grayTitle {
    font-family: "Zilla Slab";
    font-size: 20px;
    letter-spacing: 0.15px;
}

.title {
    color: $valeo_black;
}

.grayTitle {
    color: $valeo_black_60;
}

.redWarning {
    color: $dark_red;
}