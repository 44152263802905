@import "../../../../variables.scss";

.editSecurityModal {
    .modalContents {
        box-sizing: border-box;
        @include fixed-size(100%, 100%);
        padding: 24px 44px 0 44px;

        .blendedControls {
            padding-bottom: 12px;
        }
    }
}