@import "../../variables.scss";

.iasTable {

    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-width: 0px;

    td, th {
        height: 47px;
    }

    :global(.mdc-data-table__table-container) {
        @include fixed-width($tableMinWidth);
    }

    button {
        width: 44px;
        height: 44px;
        color: $gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .head {
        text-transform: uppercase;
        font-weight: 500;
    }

    .title {
        padding-left: 44px;
        background-color: $valeo_gray_100;
        text-transform: inherit;
    }

    .meetingDateAndName {
        text-transform: uppercase;
        padding-left: 44px;
        font-weight: 500;
    }

    td:nth-of-type(1) {
        padding-right: 4px;
        @include fixed-width(247px);
    }

    td:nth-of-type(2) {
        padding-left: 0px;
        padding-right: 4px;
        @include fixed-width(28px);
    }

    td:nth-of-type(3) {
        padding-left: 4px;
        padding-right: 0px;
        @include fixed-width(92px);
    }

    td:nth-of-type(4) {
        padding-left: 18px;
        padding-right: 0px;
        @include fixed-width(142px);
    }

    td:nth-of-type(5) {
        padding-left: 18px;
        padding-right: 0px;
        @include fixed-width(142px);
    }

    td:nth-of-type(6) {
        padding-left: 18px;
        padding-right: 8px;
        @include fixed-width(193px);
        overflow: visible;
    }
}