@import "../../../variables.scss";

.card {
    @include fixed-size(572px, 316px);

    .bottomRow {
        display: flex;
        justify-content: center;
        align-items: center;
        @include fixed-size(572px, 48px);
    }

    .textArea {
        margin: 12px;
        margin-bottom: 0px;

        .valeoTextArea {
            @include fixed-size(548px, 256px);
        }
    }
}

.cardElevation.cardElevationHarder {
    width: fit-content;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
}