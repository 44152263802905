@import "../../variables.scss";

.chip {
    display: flex;
    justify-content: center;
    height: 36px;
    width: fit-content;
    align-items: center;
    border-radius: 24px;
    background-color: $valeo_blue_20;
    font-family: "Outfit";
    font-size: 14px;
    font-weight: 500;
    color: $valeo_blue;
    letter-spacing: 0.1px;

    .contents {
        padding-left: 16px;
        padding-right: 16px;

    }
}