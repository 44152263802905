@import "../../../variables.scss";

.content {
    padding: 24px 28px 24px 28px;

    .nameTextField {
        width: 100%;
        @include valeo-text-field-text(400, 16px, 20px, 0.44px);

        &.nonEmptyName {
            @include valeo-text-field-non-empty-notch(41px);
        }
    }
}