@import "../../../variables.scss";

.addPositionModal {
    .dialogContents {
        box-sizing: border-box;
        @include fixed-size(100%, 100%);
        padding: 24px 44px 0 44px;

        div {
            box-sizing: border-box;
        }

        .header {
            @include fixed-size(fit-content, 21px);
            font-family: "Outfit";
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0;
            color: $valeo_black;
        }

        .topTextFields {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include fixed-size(100%, 80px);
            padding: 12px 0 12px 0;

            .tickerTextField {
                @include fixed-size(120px, 56px);
                @include valeo-text-field-text(400, 16px, 40px, 0.44px);
            }

            .currentValue {
                display: flex;
                flex-direction: column;

                .currentValueTextField {
                    @include fixed-size(132px, 56px);
                    @include valeo-text-field-text(400, 16px, 40px, 0.44px);
                }

                .currentValueInvalidEntry {
                    @include fixed-size(132px, 16px);
                    margin-top: 6px;
                    margin-bottom: -22px;
                    padding-left: 16px;
                    font-family: "Outfit";
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: $dark_red;
                }
            }
        }
    }
}