@import "../../variables.scss";

.clientTable {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;

    border-width: 0px;

    td {
        height: 56px;
    }

    th {
        height: 47px;
    }

    .tableHeader {
        background-color: $valeo_gray_100;
        padding-left: 44px;
        text-transform: none;
        letter-spacing: 0.15px;
    }

    .searchCell {
        height: 83px;
        text-align: center;

        @include valeo-text-field-table-row();

        .search {
            width: 704px;
            margin-top: 10px;

            @include valeo-text-field-text(400, 16px, 20.16px, 0.44px);
        }

        .filterIcon {
            color: $gray;
        }
    }

    .clientKey {
        text-transform: uppercase;
        padding-left: 44px;
        font-weight: 500;
        width: 280px;
    }

    .clientName {
        text-transform: uppercase;
        font-weight: 500;
        width: 332px;
    }

    .status {
        text-transform: uppercase;
        font-weight: 500;
        width: 180px;
    }
}