@import "../../../../variables.scss";

.buttonContainer {
    display: flex;
    justify-content: right;

    button.changeValueButton:not(.mdc-dialog__surface button) {
        justify-content: flex-end; // right-align the text content
        padding-left: 14px;
        padding-right: 14px;
        border: 2px solid;
        border-color: $valeo_blue;
        font-weight: 700;
        letter-spacing: 0.13125px;

        @include fixed-size(112px, 40px);

        &.wide {
            @include fixed-size(120px, 40px);
        }
    }

    :global(.mdc-button:not(:disabled)) {
        background-color: white;
    }

    button.ghost:not(.mdc-dialog__surface button) {
        color: $valeo_black_30;
    }
}