@import "../../../../variables.scss";

.row {
    .kebabCell:global(.mdc-data-table__cell) {
        padding-right: 8px;

        :global(.mdc-icon-button) {
            color: $gray;
            margin-left: auto;
            display: none;
        }

        .visible {
            display: flex;
        }
    }

    .accountNumber {
        font-weight: 500;
    }

    .accountInfo {
        font-weight: 300;
        overflow: clip;
    }
}

.row:hover {
    .kebabCell:global(.mdc-data-table__cell) {
        :global(.mdc-icon-button) {
            display: flex;
        }
    }
}