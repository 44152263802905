@import "../../../variables.scss";

.account {
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 174px;
}

.accountNumber {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.accountInfo {
    font-size: 10px;
    line-height: 14px;
}

.taxableAndMenuCell {
    overflow: visible;

    .taxableAndMenu {
        display: flex;
        white-space: normal;
        align-items: center;

        :global(.mdc-menu-surface) {
            top: 48px;
            transform-origin: left top 0px;
            height: fit-content;
        }

        .kebab {
            height: 48px;
            width: 24px;
            padding: 12px 0px 12px 0px;
            visibility: hidden;
        
            &.visible {
                visibility: visible;
            }
        }

        .taxableIcon {
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .taxableAndComments {
            display: flex;
            flex-direction: column;

            .commentIcon {
                padding: 0;
                margin: 0px 0px 4px 0px;
                @include fixed-size(24px, 24px);
                color: $gray;
            }
        }
    }
}

.commentTooltip {
    @include fixed-width(123px);

    :global(.rmwc-tooltip-content) {
        padding-top: 10px;
    }

    :global(.rmwc-tooltip-content div.rmwc-tooltip-arrow) {
        border-width: 0 9px 9px;
        top: 2px;
        left: 10px;
    }
}

.row:hover .taxableAndMenuCell .kebab {
    visibility: visible;
}

.modalAccountNumber {
    font-family: "Outfit";
    line-height: 18px;
}

.confirmationBody {
    margin-top: 8px;
}