@import "../../../../../../variables.scss";

.possessionRow {
    height: 61px;

    .holdingName {
        text-transform: uppercase;
        font-size: 16px;
        padding-left: 44px;
    }

    .chooseAccount {
        padding-right: 4px;
        overflow: visible;

        :global(.mdc-select__selected-text) {
            letter-spacing: 0.44px;
        }

        :global(.mdc-select__anchor) {
            @include fixed-width(192px);
        }

        :global(.mdc-menu-surface--open) {
            @include fixed-width(192px);
        }
    }

    .accountType {
        font-size: 16px;
    }

    .tooltipDiv {
        margin-left: auto;
        margin-right: auto;
    }

    .textField {
        padding-left: 0px;
        padding-right: 4px;
        @include valeo-text-field-table-row();
    }
}