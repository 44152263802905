@import "../../variables.scss";

.toggle {

    @include fixed-size(252px, 32px);

    :global(.mdc-tab-scroller) {
        height: 100%;

        :global(.mdc-tab-scroller__scroll-area) {
            height: 100%;
            overflow: clip;
        }
    }

    button {
        @include fixed-size(126px, 32px);
    }

    :global(.mdc-tab__text-label) {
        transition: none;
    }

    .tab {
        :global(.mdc-tab-indicator) {
            @include fixed-size(124px, 30px);
        }

        :global(.mdc-tab-indicator--active) {
            background-color: $valeo_blue;

            :global(.mdc-tab-indicator__content--underline) {
                visibility: hidden;
            }
        }
    }

    :global(.mdc-tab:not(.mdc-tab--active)) {
        :global(.mdc-tab__text-label) {
            color: $valeo_black_60;
            opacity: 1;
        }
    }

    .tabOne {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;

        :global(.mdc-tab-indicator) {
            border: 1px solid $valeo_black_10;
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }

    }

    .tabTwo {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;

        :global(.mdc-tab-indicator) {
            border-right: 1px solid $valeo_black_10;
            border-bottom: 1px solid $valeo_black_10;
            border-top: 1px solid $valeo_black_10;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
        }
    }

    :global(.mdc-tab--active) {
        :global(.mdc-tab__content) {
            color: white;
        }
    }
}