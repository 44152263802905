@import "../../variables.scss";

.linearProgressOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    z-index: 1000;
    border-radius: 4px;
    top: 0;

    &.fullscreen {
        border-radius: 0px;
        position: fixed;
        left: 0;
    }

    .centeredContent {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 100%;

        .linearProgress {
            margin: auto;

            :global(.mdc-linear-progress__bar-inner) {
                border-top: 16px solid;
            }

            :global(.mdc-linear-progress__buffer-bar) {
                background-color: $valeo_blue;
                opacity: 0.24;
            }

            @include fixed-size(496px, 16px);
            color: $valeo_blue;
        }

        .text {
            color: $valeo_black;
            font-family: "Zilla Slab";
            font-size: 48px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 28px;
        }
    }

    .exit {
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 48px;
        margin-top: 46px;
        margin-right: 46px;
        color: $valeo_black_60;

        .closeButton {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            font-size: 48px;
            color: $valeo_black_60;
        }
    }
}