@import "../../../variables.scss";

.table {
    @include table-row-transparent-hover();
    margin-bottom: 14px;

    &.wide {
        @include fixed-width($tableMaxWidth);
    }

    &.narrow {
        @include fixed-width($tableMinWidth);
    }

    td {
        font-weight: 500;
    }

    th {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }

    th.valueColumn {
        text-align: right;
    }
}

.assetClass {
    background-color: $valeo_gray_40;
    font-weight: 500;
    font-size: 14px;
}

.segment {
    @include fixed-width(266px)
}

.ticker {
    @include fixed-width(92px);
}

.value {
    text-align: right;
    @include fixed-width(116px);
}

.percentage {
    text-align: right;
    padding-left: 4px;
    @include fixed-width(68px);
}

.rightBorder {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}