@import "../../../../variables.scss";

.title {
    position: relative;
    z-index: 2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    @include fixed-height(129px);

    display: flex;
    align-items: center;
    padding-left: 44px;
    font-family: "Zilla Slab";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: $valeo_black;
}