@import "../../../variables.scss";

.name {
    padding-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    color: $valeo_black;

    .ticker {
        padding-right: 12px;
    }
}

.row {
    display: flex;
    padding: 12px 0 12px 0;

    .column1 {
        @include fixed-width(324px);

        .addRow {
            :global(.mdc-button__label) {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1px;
                color: $valeo_blue;
            }
        }
    }

    .column2 {
        display: flex;
        align-items: center;
        @include fixed-width(159px);

        .percentageRemaining {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            white-space: pre;
            color: $valeo_black;

            &.negativePercentageRemaining {
                color: $dark_red;
            }
        }
    }

    .column3 {
        display: flex;
        align-items: center;
    }
}