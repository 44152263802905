@import "../../../variables";

.percentageEntry:global(.mdc-text-field) {
    @include fixed-size(84px, 40px);

    :global(.mdc-text-field__input) {
        font-size: 14px;
        font-weight: 500;
    }
}


.warningLabel {
    margin-right: 16px;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Outfit';
    color: $valeo-black;
    line-height: 16px;
    letter-spacing: 0.4px;
}