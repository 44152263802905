@import "../../../../variables.scss";

.title {
    position: absolute;
    font-family: 'Zilla Slab';
    color: white;
    font-size: 24px;
}

.cancelBtn {
    padding-top: 3px;
}