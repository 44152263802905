@import "../../../variables.scss";

.accountSelect {
    @include valeo-select-size(192px, 56px);
    @include valeo-select-selected-text(400, 16px, 20px, 0.44px);
    @include valeo-select-option-text(400, 16px, 52px, 0.44px);

    :global(.mdc-select__selected-text) {
        @include fixed-height(20px);
        white-space: pre;
    }

    :global(.mdc-select__menu) {
        :global(.mdc-list.mdc-menu__items) {
            :global(.mdc-list-item) {
                @include fixed-height(fit-content); // override ValeoSelect

                .account {
                    .accountNumber {
                        padding: 18px 0 4px 0;
                        font-family: "Outfit";
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.44px;
                    }

                    .accountInfo {
                        padding: 2px 0 2px 0;
                        font-family: "Outfit";
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 10px;
                    }
                }

                .newAccount {
                    font-weight: 600;
                }
            }
        }
    }
}