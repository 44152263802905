@import "../../../../../../variables.scss";

.table {
    @include table-row-transparent-hover();
    @include remove-table-border();

    .sectionTitle {
        color: $valeo_black;
        font-weight: 500;
        height: 54px;
        padding-left: 44px;
    }

    .columnHeaders {
        td {
            color: $valeo_black;
            font-weight: 500;
            font-size: 16px;
            vertical-align: bottom;
            padding-bottom: 8px;
        }

        td.noPadding {
            padding-left: 0px;
        }
    }

    td:nth-of-type(1) {
        @include fixed-width(409px);
    }

    td:nth-of-type(2) {
        @include fixed-width(212px);
    }

    td:nth-of-type(3),
    td:nth-of-type(4) {
        @include fixed-width(192px);
    }

    td:nth-of-type(5) {
        @include fixed-width(239px);
    }
}