@import "../../variables.scss";

.red {
    color: #B00020;
    font-size: 14px;
}

.menuAnchor {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu {
    width: 131px;
    translate: 30px;
}

.menuItem {
    font-size: 14px;
}

.draft {
    color: $valeo_blue_100;
    font-weight: 600;
}

.review {
    color: $valeo_blue;
    font-weight: 600;
}

.final {
    color: #4F7538;
    font-weight: 600;
}

.notesIcon {
    color: $gray;
}

.notesTooltip {
    @include fixed-width(123px);

    :global(.rmwc-tooltip-content) {
        padding-top: 10px;
    }

    :global(.rmwc-tooltip-content div.rmwc-tooltip-arrow) {
        border-width: 0 9px 9px;
        top: 2px;
        left: 10px;
    }
}

.wrap {
    white-space: normal;
    overflow-wrap: anywhere;
}

.modelPortfolio {
    width: 123px;
}