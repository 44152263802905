@import "../../variables.scss";

.datePicker {
    :global(.react-datepicker) {
        font-family: 'Outfit';
        border: none;

        :global(.react-datepicker__navigation) {
            height: 24px;
            width: 44px;

            :global(.react-datepicker__navigation-icon--previous) {
                right: 0px;
            }

            :global(.react-datepicker__navigation-icon--next) {
                left: 0px;
            }
        }

        :global(.react-datepicker__navigation-icon::before) {
            border-color: $valeo_black_60;
            border-width: 2px 2px 0 0;
            height: 7px;
            width: 7px;
        }

        :global(.react-datepicker__header) {
            background-color: white;
            border: none;
            padding-top: 0px;

            :global(.react-datepicker__current-month) {
                font-size: 20px;
                font-weight: 500;
            }

            :global(.react-datepicker__day-names) {
                :global(.react-datepicker__day-name) {
                    width: 40px;
                    margin-left: 3px;
                    margin-right: 3px;
                    color: $valeo_black_60;
                    font-size: 12px;
                }

                margin-top: 10px;
            }
        }

        :global(.react-datepicker__month) {
            margin: 0px;
            margin-top: 10px;

            :global(.react-datepicker__day) {
                font-size: 14px;
                color: $valeo_black_90;
                width: 40px;
                height: 40px;
                margin: 0px 3px 0px 3px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            :global(.react-datepicker__day:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected):hover) {
                border-radius: 500px;
            }

            :global(.react-datepicker__day--selected) {
                color: white;
                background-color: $valeo_blue;
                border-radius: 500px;
            }

            :global(.react-datepicker__day--outside-month) {
                visibility: hidden;
            }

            :global(.react-datepicker__day--today) {
                font-weight: 500;
            }

            :global(.react-datepicker__day--today:not(.react-datepicker__day--selected):not(.react-datepicker__day--keyboard-selected)) {
                height: 38px;
                width: 38px;
                border: 1px solid $valeo_black_60;
                border-radius: 500px;
            }
        }
    }
}