@import "../../../variables.scss";

.dialogContents {
    font-size: 14px;
    line-height: 14px;
    color: $valeo_black;
    letter-spacing: normal;

    .position {
        padding-left: 44px;
        padding-top: 15px;
        padding-bottom: 7px;
        height: 14px;

        .ticker {
            padding-right: 12px;
        }
    }

    .account {
        @include fixed-height(34px);
        background-color: $valeo_gray_20;

        padding-top: 8px;
        padding-bottom: 8px;

        .accountNumber {
            font-weight: 500;
            padding-left: 44px;
            padding-bottom: 6px;
        }

        .accountInfo {
            text-transform: uppercase;
            font-weight: 300;
            padding-left: 44px;
        }
    }

    .textArea {
        @include fixed-size(392px, 120px);
    }

    :global(.mdc-tab-bar) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    :global(.mdc-text-field) {
        margin-left: 28px;
        margin-bottom: 8px;
    }
}