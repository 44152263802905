@import "../../../../../variables.scss";

.table {
    width: 100%;
    padding: 0px;
    @include remove-table-border();

    :global(.mdc-data-table__row) {
        height: 61px;
    }

    :global(.mdc-data-table__cell),
    :global(.mdc-data-table__header-cell) {
        padding-left: 0;
    }

    .indent1 {
        @include fixed-width(44px);
    }

    .indent2 {
        @include fixed-width(129px);
    }

    .mediumFont {
        font-family: "Outfit";
        font-weight: 500;
        font-size: 14px;
        color: $valeo_black_90;
    }

    .lightFont {
        font-family: "Outfit";
        font-weight: 300;
        font-size: 14px;
        color: $valeo_black;
    }

    .accountNumber {
        font-weight: 500;
        color: $valeo_black;
        @include fixed-width(290px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .column1 {
        display: inline-flex;
        align-items: center;
        @include fixed-width(470px);
        padding-right: 0px;
        min-height: 61px;

        .chevron {

            button {
                @include fixed-width(40px);
                padding: 8px;
            }
        }
    }

    .column2 {
        display: inline-flex;
        align-items: center;
        $padding-left: 72px;
        @include fixed-width(154px + $padding-left);
        padding-left: $padding-left;
        padding-right: 0px;
        white-space: normal;
        height: fit-content;
    }

    .column3 {
        display: inline-flex;
        align-items: center;
        $padding-left: 24px;
        @include fixed-width(99px + $padding-left);
        padding-left: $padding-left;
        padding-right: 0px;
        text-align: right;
        justify-content: flex-end;
    }

    .column4 {
        display: inline-flex;
        align-items: center;
        $padding-left: 24px;
        padding-left: $padding-left;
        @include fixed-width(121px + $padding-left);
    }

    .column5.column5 {
        display: inline-flex;
        $padding-left: 24px;
        padding-left: $padding-left;
        @include fixed-width(216px + $padding-left);
        overflow: visible;
        align-items: center;
    }

    .checkbox {
        margin-right: 11px;

        label {
            cursor: pointer;
        }
    }

    .alignVerticalCenter {
        display: flex;
        align-items: center;
    }

    .bgTransparent:hover {
        background-color: transparent;
    }

    .duplicate {

        .column1,
        .column2,
        .column3 {
            color: $valeo_black_30;
        }
    }
}

.noAssets {
    display: flex;
    flex-direction: column;
    font-family: "Outfit";
    font-size: 20px;
    color: $valeo_black;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    @include fixed-height(584px);

    .launchBlackDiamond {
        margin-top: 24px;
    }
}