@import "../../variables.scss";

.fullScreenOverlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    z-index: 1000;
    border-radius: 0px;
    top: 0;

    .centeredContent {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 100%;

        .text {
            color: $valeo_black;
            font-family: "Zilla Slab";
            font-size: 48px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 28px;
        }
    }
}