@import "../../variables.scss";

.dialogContent {
    @include fixed-height(100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpText {
    margin-right: 24px;
    color: $valeo_black;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}