@import "../../../variables.scss";

.footerFiller {
    flex-grow: 1;
}

.saveAndAddNew {
    margin-right: 8px;
}

.errorText {
    margin-right: 24px;
    color: $valeo_black;
    font-family: "Outfit";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}