@import "../../../../variables.scss";

.header {
    @include fixed-height(21px);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: $valeo_black;
}

.row {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;

    .tickerTextField {
        @include fixed-size(120px, 56px);
        @include valeo-text-field-text(400, 16px, 20px, 0.44px);
        margin-right: 32px;

        &.nonEmptyTicker {
            @include valeo-text-field-non-empty-notch(43px);
        }
    }

    .nameTextField {
        @include fixed-size(344px, 56px);
        @include valeo-text-field-text(400, 16px, 20px, 0.44px);
        margin-right: 32px;

        &.nonEmptyName {
            @include valeo-text-field-non-empty-notch(42px);
        }
    }
}