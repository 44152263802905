@import "../../../variables.scss";

.menu {
    color: $valeo_black_90;
    width: 133px;

    li {
        padding-left: 20px;
        padding-right: 20px;
        height: 36px;
        font-size: 14px;
        letter-spacing: 0.25px;
    }

    .red {
        color: $dark_red;
    }
}