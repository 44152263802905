@import "../../../../../variables.scss";

.portfolios {
    button.launchBlackDiamond {
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: $valeo_blue;

        span {
            line-height: 16px;
        }
    }

    .portfoliosFound .launchBlackDiamond {
        margin: 24px 0 24px 44px;
    }

    .noPortfolios {
        display: flex;
        flex-direction: column;
        font-family: "Outfit";
        font-size: 20px;
        color: $valeo_black;
        font-weight: 400;
        justify-content: center;
        align-items: center;
        @include fixed-height(584px);

        .launchBlackDiamond {
            margin-top: 24px;
        }
    }

    .connectionFailure {
        line-height: 25.2px;
        letter-spacing: 0.44px;
    }
}

.redError {
    color: $dark_red
}

