@import "../../../variables.scss";

.recommendedFundRow {
    display: flex;
    align-items: center;
    @include fixed-height(36px);
    margin-top: 12px;

    .actionButton {
        @include fixed-width(111px);
    }

    .fundDetails {
        @include fixed-height(20px);
        padding-left: 12px;
        font-family: "Outfit";
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.44px;
        color: $valeo_black;
        white-space: pre-wrap;
    }

    .inIasChip {
        margin-left: 12px;
        background-color: $valeo_yellow_20;
        color: $valeo_yellow_130;
    }
}