@import "../../../../variables.scss";

.securityInformation {
    font-size: 18px;
    font-weight: 500;
    padding-left: 44px;
    padding-top: 15px;
    padding-bottom: 12px;
    color: $valeo_black;

    .ticker {
        padding-right: 12px;
    }
}

.elevation {
    position: relative;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);
    background-color: $valeo_gray_20;
    @include fixed-height(78px);

    display: flex;

    .informationColumn {
        padding-left: 44px;
        @include fixed-width(366px);
        color: $valeo_black;
        white-space: nowrap;

        .accountNumber {
            padding-top: 26px;
            font-size: 18px;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .accountDetails {
            font-weight: 300;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .whiteSpaceNoWrap {
            white-space: nowrap;
        }
    }

    .valueColumn {
        @include fixed-width(120px);
        margin-right: 80px;
        color: $valeo_black;

        .header {
            text-align: right;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            padding-top: 4px;
            padding-bottom: 2px;
        }

        .textboxControl {
            @include fixed-size(120px, 40px);

            :global(.mdc-text-field__input) {
                font-weight: 700;
            }
        }

        .displayValue {
            text-align: right;
            @include fixed-height(40px);
            padding-top: 8px;
            font-weight: 700;
            font-size: 14px;
        }
    }
}

.table {
    @include table-row-transparent-hover();
    @include fixed-width(100%);
    @include remove-table-border();
    @include standard-table-row-borders();

    margin-bottom: 24px;

    td {
        font-weight: 500;
        color: $valeo_black;
    }

    tr.originRow {
        td {
            font-weight: 700;
        }
    }

    .assetClassRow {
        td:nth-of-type(1) {
            font-size: 16px;
            padding-left: 44px;
            width: 425px;
        }
    }

    .segmentRow {
        td:nth-of-type(1) {
            padding-left: 88px;
            width: 425px;
        }
        td:nth-of-type(2) {
            width: 184px;
            padding-right: 80px;
            text-align: right;
        }
        td:nth-of-type(3), td:nth-of-type(4) {
            width: 200px;
            padding-right: 80px;
            text-align: right;
        }
    }
}