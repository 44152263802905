@import "../../../../../variables.scss";

.row {
    height: 60px;


    .toggleButtonCell {
        padding: 0;
        width: 48px;
    }

    .nameCell {
        width: 475px;
        text-transform: uppercase;
        padding-left: 0px;

        .ticker {
            padding-right: 12px;
        }
    }

    .selectCell {
        width: 724px;
        overflow: visible;
    }
}

.accountLabel {
    padding-left: 0px;
    font-weight: 500;
    font-size: 16px;
}

.accountInfoRow {
    border-top: none;
    height: 30px;

    &:global(.mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
        background-color: transparent;
    }

    td {
        height: 30px
    }

    .accountInfoCell {
        text-transform: uppercase;
        white-space: pre;
        font-weight: 300;
        padding-left: 0px;
        width: 475px;
        font-size: 14px;
    }
}