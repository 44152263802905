@import "../../../variables.scss";

.cardElevation.important {
    width: fit-content;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
}

.card {
    width: 240px;
    padding: 12px;
    overflow: hidden;
}

.title {
    color: $gray;
    padding-bottom: 5px;
}

.value {
    color: $valeo_black;
    font-family: Zilla Slab;
    line-height: 28px;
    font-size: 24px;
    font-weight: 500;
}

.valueBox {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: auto;
    margin-bottom: auto;
    word-break: break-word;
}

.floatRight {
    float: right;
}