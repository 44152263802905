@import "../../../../variables.scss";

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include fixed-height(47px);
    width: 100%;
    padding: 0 44px 0 44px;
    border-radius: 4px 4px 0 0;
    background-color: $valeo_blue;
}