@import "../../../../variables.scss";

button:global(.mdc-button).button {
    @include fixed-height(36px);
    border-radius: 2px;
    background-color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;

    &.enabled {
        color: $valeo_blue;
    }

    &.disabled {
        color: $valeo_black_60;
    }
}