@import "../../../variables.scss";

.customSegmentNameMenu {
    .menu {
        @include kebab-menu-hover();

        &.widestOptionIsRevertLabel {
            translate: -85px;
        }

        &.widestOptionIsEditLabel {
            translate: -69px;
        }

        :global(.mdc-list.mdc-menu__items.mdc-list) {
            padding: 8px 0;
        }
    }

    .menuItem {
        padding-right: 20px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.25px;
        color: $valeo_black_90;
        white-space: nowrap;

        &:global(.mdc-list-item) {
            @include fixed-height(36px);
        }
    }

    .visible.visible.visible.visible {
        display: flex;
    }
}