@import "../../variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    @include fixed-height(140px);
    padding-left: $fabSize + $fabMargin;
    padding-right: $fabSize + $fabMargin;

    button:global(.mdc-button).addIasButton {
        @include fixed-height(48px);
        border-radius: 24px;
        padding: 12px 16px 12px 16px;
        margin-top: 24px;
        background-color: $valeo_blue;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 6px rgba(0, 0, 0, 0.18);
        color: white;
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 500;

        :global(.mdc-button__icon) {
            @include fixed-size(24px, 24px);
            font-size: 24px;
            margin: -2px 12px -2px 0;
        }
    }

    .modelPortfolio {
        margin-top: 24px;
        :global(.mdc-card) {
            @include fixed-height(76px);
        }
    }
}

.bottomWhitespace {
    @include fixed-height(200px);
}