@import "../../../../variables.scss";

.row {
    .kebabCell:global(.mdc-data-table__cell) {
        padding-right: 8px;

        :global(.mdc-icon-button) {
            color: $gray;
            margin-left: auto;
            display: none;
        }

        .visible {
            display: flex;
        }
    }

    .securityRow {
        font-weight: 700;
    }

    .securityName {
        font-weight: 700;
    }

    .ticker {
        padding-right: 12px;
    }

    &:hover {
        .kebabCell:global(.mdc-data-table__cell) {
            :global(.mdc-icon-button) {
                display: flex;
            }
        }
    }

    .disableRowBasedCursor {
        cursor: auto;
    }
}