@import "../../../variables.scss";

.sidebar {
    @include fixed-height(calc(100vh - 32px));
    @include fixed-width(457px);

    position: sticky;
    top: 16px;

    button {
        color: $gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow {
        width: 44px;
        height: 44px;
    }
}

.closedSidebar {
    @include fixed-width(41px);
    padding-left: 6px;
    padding-right: 6px;
}

.sidebarContent {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.hidden {
    visibility: hidden;
}

:global(.test-mode) {
    .sidebar {
        position: static;
        height: fit-content;
    }
}