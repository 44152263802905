@import "../../variables.scss";

.dialog {
    :global(.mdc-dialog__surface) {
        @include fixed-size(fit-content, fit-content);
    }

    cursor: auto;

    button {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.actions {
    padding-right: 44px;
}

.dialogTitle {
    width: 100%;
    padding: 0px 24px 13px 24px;

    .title {
        font-family: 'Zilla Slab';
        color: $valeo_black;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }
}

.dialogBody {
    font-family: 'Outfit';
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    letter-spacing: 0.44px;
    white-space: pre-wrap;
}

.textLine {
    margin-bottom: 20.16px;
}

.buttonWarning {
    :global(.mdc-button__label) {
        color: $dark_red;
    }
}

.buttonBlue {
    :global(.mdc-button__label) {
        color: $valeo_blue;
    }
}

.buttonCancel {
    :global(.mdc-button__label) {
        color: $valeo_black_60;
    }
}