@import "../../../../../variables.scss";
.helpTextContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    @include fixed-height(100%);

    .helpText {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-right: 24px;
        color: $valeo_black;
    }
}