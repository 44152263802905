@import "../../../../variables.scss";

.createCustomGroupModal {
    .modalContents {
        box-sizing: border-box;
        @include fixed-size(100%, 100%);
        padding: 0 0 24px 0;

        .header {
            padding: 24px 44px 0 44px;

            .groupNameField {
                @include fixed-size(344px, 56px);
                @include valeo-text-field-text(400, 16px, 40px, 0.44px);

                &.disabled {
                    @include valeo-text-field-non-empty-notch(80px);
                }
            }

            .selectButtons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: row-reverse;
                @include fixed-size(100%, 60px);

                button {
                    margin: 0;

                    &.deselectAllButton {
                        margin-left: 12px;
                    }
                }
            }
        }

        .table {
            :global(.mdc-data-table) {
                @include fixed-width(100%);
                @include remove-table-border();
            }

            table {
                @include fixed-width(100%);
            }

            tr.segmentRow:nth-of-type(1), // override remove-table-border(), which assumes presence of elevation (with shadow) directly above table
            .securityRow {
                border-width: 0px;
                border-top-style: solid;
                border-top-width: 1px;
                border-top-color: $valeo_black_10;

                &:hover {
                    background-color: transparent;
                }

                td {
                    display: inline-flex;
                    align-items: center;
                    min-height: 48px;
                    height: fit-content;
                    @include fixed-width(100%);
                }

                .customGroupCheckbox {
                    white-space: pre-wrap;

                    label {
                        padding-left: 12px;
                        font-family: "Outfit";
                        font-size: 14px;
                        font-weight: 700;
                        color: $valeo_black;
                    }

                    :global(.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background) {
                        border-color: $valeo_black_60;
                        border-width: 2px;
                        border-radius: 2px;
                    }
                }
            }

            .segmentRow {
                td {
                    padding: 0 0 0 44px;
                    font-family: "Outfit";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    color: $valeo_black;
                }
            }

            .securityRow {
                td {
                    padding: 0 0 0 33px;
                }
            }
        }
    }

    .helpTextContainer {
        display: flex;
        align-items: center;
        @include fixed-height(100%);

        .helpText {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin-right: 24px;
            color: $valeo_black;
        }
    }
}