@import "../../../variables.scss";

.title_cell {
  font-weight: 500;
}

.medium_weight {
  font-weight: 500;
}

.indent_0 {
  padding-left: 0px;
}

.indent_1 {
  padding-left: 44px;
}

.indent_2 {
  padding-left: 88px;
}

.indent_3 {
  padding-left: 132px;
}

.indent_4 {
  padding-left: 176px;
}

.value_column {
  text-align: right;
}

.target_and_variance_column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.variance_icon {
  padding-left: 18px;

  svg {
    vertical-align: middle;
  }
}

.variance_icon.wide {
  padding-left: 60px;
}

.percentageDiv {
  height: 20px;
  font-weight: 500;
}

.redLabel {
  font-weight: 700;
  color: $valeo_red_orange_130;
}

.blueLabel {
  font-weight: 700;
  color: $valeo_blue_130;
}

.yellowLabel {
  font-weight: 700;
  color: $valeo_yellow_130;
}

.collapsibleGroup {
  display: flex;
  align-items: center;
}