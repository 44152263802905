@import "../../variables.scss";

.selectContainer {
    display: contents;
}

.hideFloatingLabel.hideFloatingLabel.hideFloatingLabel {
    &:global(.mdc-select--focused),
    &:global(.mdc-select--activated),
    &:global(.mdc-ripple-upgraded--foreground-activation) {
        :global(.mdc-notched-outline__notch) {
            border-top: 2px solid;
        }
    }

    :global(.mdc-notched-outline__notch) {
        border-top: 1px solid;
    }

    :global(.mdc-floating-label--float-above) {
        display: none;
    }

    :global(.mdc-menu-surface) {
        margin-bottom: 0px;
    }
}

:global(.mdc-select):global(.mdc-select--disabled).select:global(.mdc-select--outlined).disabled {
    background-color: white;
    margin: 0;
    border-radius: 4px;
    color: $valeo_black_30;
    :global(.mdc-notched-outline) :global(.mdc-notched-outline__leading),:global(.mdc-notched-outline__trailing),:global(.mdc-notched-outline__notch) {
        border-color: $valeo_black_30;
    }
    :global(.mdc-select__selected-text) {
        color: $valeo_black_30;
    }
    :global(.mdc-floating-label):global(.mdc-floating-label--float-above) {
        color: $valeo_black_30;
    }
}

.select:global(.mdc-select--outlined):not(.disabled) {
    background-color: white;
    margin: 0;
    border-radius: 4px;

    :global(.mdc-select__selected-text) {
        color: $valeo_black; // selected text
    }

    :global(.mdc-floating-label) {
        color: $valeo_black_60; // placeholder label (non-floating), floating label (inactive)
    }

    :global(.mdc-select__anchor) {
        color: $valeo_black_60; // arrow icon (inactive)

        :global(.mdc-notched-outline__leading),
        :global(.mdc-notched-outline__notch),
        :global(.mdc-notched-outline__trailing) {
            border-color: $valeo_black_60; // border (inactive, no hover)
        }

        :global(.mdc-notched-outline__notch) {
            padding-right: 0; // override RMWC
        }

        :global(.mdc-select__dropdown-icon) {
            margin-left: 8px;
            margin-right: 8px;
        }

        &:hover {
            :global(.mdc-notched-outline.mdc-notched-outline--upgraded) {

                :global(.mdc-notched-outline__leading),
                :global(.mdc-notched-outline__notch),
                :global(.mdc-notched-outline__trailing) {
                    border-color: $valeo_black_60; // border (inactive, hover)
                }
            }
        }
    }

    &:global(.mdc-select--focused) {
        :global(.mdc-select__anchor.mdc-ripple-upgraded) {
            color: $valeo_blue; // floating label (active), arrow icon (active)

            :global(.mdc-notched-outline__leading),
            :global(.mdc-notched-outline__notch),
            :global(.mdc-notched-outline__trailing) {
                border-color: $valeo_blue; // border (active, no hover)
            }

            &:hover {
                :global(.mdc-notched-outline__leading),
                :global(.mdc-notched-outline__notch),
                :global(.mdc-notched-outline__trailing) {
                    border-color: $valeo_blue; // border (active, hover)
                }
            }
        }
    }

    :global(.mdc-menu-surface) {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 12px rgba(0, 0, 0, 0.24);
    }

    :global(.mdc-menu__items),
    :global(.mdc-list:first-child:last-child.mdc-menu__items) { // override RMWC for list inside dialog
        padding-top: 8px;
        padding-bottom: 8px;
    }

    :global(.mdc-select__selected-text) {
        text-overflow: clip;
    }

    :global(.mdc-select__menu) {

        :global(.mdc-list-item.mdc-list-item--activated) { // selected option
            background-color: rgba(0, 0, 0, 0.04);
        }

        :global(.mdc-ripple-upgraded--background-focused:not(.mdc-ripple-upgraded--foreground-activation).mdc-list-item__ripple) {

            &::before,
            &::after { // option with keyboard focus
                background-color: white;
                opacity: 0;
            }
        }

        :global(.mdc-ripple-upgraded--background-focused.mdc-list-item:hover) {
            background-color: rgba(0, 0, 0, 0.04); // option with keyboard focus (hover)
        }

        :global(.mdc-list-item) {
            @include fixed-height(40px);
            color: $valeo_black;
        }
    }
}