@import "../../../../variables.scss";

:global(.mdc-dialog__actions).dialogActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include fixed-height(52px);
    border: none;
    padding: 0 44px 0 44px;
    border-radius: 0 0 4px 4px;
    color: $valeo_blue;
}