@import "../../../variables.scss";

.excessRemainingTable {
    @include fixed-width(412px);
    margin-top: 16px;

    thead {
        border-bottom: 1px solid $valeo_black_10;
        border-collapse: collapse;
    }

    tr {
        border-color: $valeo_black_10;
    }

    .headRow {
        display: block;

        th:nth-of-type(1) {
            font-size: 14px;
            font-family: 'Zilla Slab';
            text-transform: none;
            @include fixed-width(184px);
        }

        th:nth-of-type(2) {
            padding-right: 12px;
            text-align: right;
            font-size: 14px;
            @include fixed-width(226px);
        }

        :global(.mdc-data-table__header-cell) {
            height: 47px;
        }
    }

    .columnLabelRow {
        display: block;

        th:nth-of-type(1) {
            font-size: 12px;
            @include fixed-width(183px);
        }

        th:nth-of-type(2) {
            @include fixed-width(54px);
        }

        th:nth-of-type(3) {
            padding-right: 6px;
            text-align: right;
            font-size: 12px;
            @include fixed-width(77px);
        }

        th:nth-of-type(4) {
            padding-right: 12px;
            text-align: right;
            font-size: 12px;
            @include fixed-width(96px);
        }

        :global(.mdc-data-table__header-cell) {
            height: 20px;
        }

        text-transform: uppercase;
        font-size: 12px;
    }

    .body {
        display: block;
        // total viewport height minus height of arrow button, bar chart, lower padding, table margin, table header, and space above and below sidebar
        height: (calc(100vh - 44px - 262px - 24px - 16px - 71px - 32px - 6px));
        overflow-y: scroll;
        scrollbar-width: none;

        tr:nth-of-type(1) {
            border-top: none;
        }
    }

    .body::-webkit-scrollbar {
        display: none;
    }

    :global(.mdc-data-table__cell) {
        height: 40px;
    }

    td {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 12px;
    }

    td:nth-of-type(1) {
        padding-left: 10px;
        padding-right: 0px;
        @include fixed-width(184px);
    }

    td:nth-of-type(2) {
        padding-right: 3px;
        padding-left: 3px;
        @include fixed-width(54px);
    }

    td:nth-of-type(3) {
        padding-right: 6px;
        padding-left: 0px;
        @include fixed-width(77px);
        text-align: right;
        font-size: 12px;
    }

    td:nth-of-type(4) {
        padding-right: 12px;
        padding-left: 4px;
        @include fixed-width(96px);
        text-align: right;
        font-size: 12px;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }

    th:nth-of-type(1) {
        padding-left: 10px;
    }
}