@import "../../../../variables.scss";

.underlined {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.commentTooltip {
    @include fixed-width(123px);

    :global(.rmwc-tooltip-content) {
        padding-top: 10px;
    }

    :global(.rmwc-tooltip-content div.rmwc-tooltip-arrow) {
        border-width: 0 9px 9px;
        top: 2px;
        left: 10px;
    }
}