@import "../../variables.scss";

.iconRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 24px;

    .icon {
        @include fixed-height(48px);
        margin-top: auto;
        margin-bottom: auto;
    }
}

.messageRow {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .text {
        color: $valeo_black;
        font-family: "Zilla Slab";
        font-size: 48px;
        font-weight: 500;
        text-align: center;

        p {
            margin: 0;
        }
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 24px;
}

.button:global(.mdc-button:not(:disabled)) {
    background-color: $valeo_blue;
    color: white;
    text-decoration: none;
}