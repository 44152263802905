@import "../../../variables.scss";

.dialog {
    overflow: auto;
    padding-top: 50px;
    padding-bottom: 50px;

    :global(.mdc-dialog__container) {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 0;
        height: fit-content;
        padding-left: calc(100vw - 100%);
    }

    :global(.mdc-dialog__surface) {
        @include fixed-size(fit-content, fit-content);
        overflow: visible;
    }

    :global(.mdc-dialog__content) {
        padding: 0;
        overflow: visible;
    }
}