@import "../../variables.scss";

.paginationRow:global(.mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
    background-color: transparent; 
}

.paginationContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: $valeo_black;

    .resultsMessage {
        white-space: pre;
    }

    .firstButton {
        margin-left: 18px;
    }

    .lastButton {
        margin-right: 16px;
    }

    .disabled {
        color: $valeo_black_30;
    }
}