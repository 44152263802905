@import "../../../variables.scss";

.barChart {
    .cash_80 {
        fill: $valeo_green_80;
    }

    .cash_130 {
        fill: $valeo_green_130;
    }

    .alternatives_80 {
        fill: $valeo_yellow_80;
    }

    .alternatives_130 {
        fill: $valeo_yellow_130;
    }

    .equities_80 {
        fill: $valeo_red_orange_80;
    }

    .equities_130 {
        fill: $valeo_red_orange_130;
    }

    .fixed_income_80 {
        fill: $valeo_blue_80;
    }

    .fixed_income_130 {
        fill: $valeo_blue_130;
    }

    .yAxis {
        :global(.domain) {
            stroke: $valeo_gray_100;
        }

        :global(.tick) line {
            stroke: $valeo_gray_100;
        }
    }

    text {
        font-family: "Outfit";
        font-size: 10px;
        font-weight: 400;

        &.keyLabel {
            font-size: 12px;
        }
    }

    .strokeGrey {
        stroke: $valeo_gray_100;
    }
}

.col1 {
    @include fixed-width(88px);
}

.col2 {
    @include fixed-width(36px);
    padding-left: 8px;
    text-align: right;
    align-self: end;
}

.col3 {
    @include fixed-width(80px);
    padding-left: 8px;
    text-align: right;
    align-self: end;
}

.rowPad {
    padding-top: 5px;
    overflow: hidden;
}

.tooltipRow {
    display: flex;
}

.axisLabel {
    text-anchor: middle;
}

.proposedGroup {
    padding-top: 20px;
}
