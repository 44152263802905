@import "../../variables.scss";

.navMenu:global(.EZDrawer__container) {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $valeo_blue;
    color: white;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.18), 2px 0px 4px rgba(0, 0, 0, 0.24);

    .header {
        @include fixed-height(94px);
        border-bottom: 1px solid $valeo_black_10;

        .close {
            margin-left: 20px;
            margin-top: 12px;
        }

        .vtap {

            position: absolute;
            left: 50%;
            margin-top: -3px;
            transform: translateX(-50%) translateY(-50%);

            .logo {
                @include fixed-size(50px, 50px);
            }
        }
    }

    .content {
        .search {
            margin-top: 28px;
            margin-bottom: 24px;
            margin-left: 16px;
            @include fixed-width(238px);
            border-radius: 4px;

            @include valeo-text-field-text(400, 16px, 20.16px, 0.44px);

            :global(.mdc-floating-label--float-above) {
                display: none;
            }

            &:global(.mdc-text-field--focused) {
                :global(.mdc-notched-outline) {
                    :global(.mdc-notched-outline__notch) {
                        border-top: 2px solid $valeo_blue;
                    }
                }
            }
        }

        .clientList {
            color: $valeo_black_30;
            letter-spacing: 0.44px;
            padding-left: 16px;
        }

        .client:global(.mdc-list-item) {
            @include fixed-height(56px);
            letter-spacing: 0.44px;
            justify-content: space-between;

            :global(.mdc-list-item__ripple.mdc-ripple-upgraded) {

                &::before,
                &::after {
                    background-color: white
                }
            }

            &.selected {
                background-color: rgba(255, 255, 255, 0.38);
            }

            .clientKey {
                @include fixed-width(198px);
                overflow: clip;
                max-height: 100%;
            }
        }
    }
}

.fullClientListContainer {
    display: flex;
    justify-content: center;
    padding: 24px 0 24px 0;

    button:global(.mdc-button:not(:disabled)) {
        background-color: white;
        color: $valeo_blue;
    }
}