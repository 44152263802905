@import "../../../../../variables.scss";

tr.portfolio {
    @include fixed-height(48px);
    border-width: 0px;

    td {
        display: inline-flex;
        align-items: center;
        @include fixed-height(48px);
        padding: 0;
        border-width: 0px;
        overflow: visible;
        white-space: normal;
    }

    td.chevron {
        @include fixed-width(40px);

        button {
            @include fixed-width(40px);
            padding: 8px;
            color: $valeo_black_60;
        }
    }

    td.portfolioName {
        @include fixed-width(720px);
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 400;

        :global(.mdc-radio) {
            padding: 10px 22px 10px 14px;

            :global(.mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle) {
                border-color: $valeo_black_60;
            }

            :global(.mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle),
            :global(.mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle) {
                border-color: $valeo_blue;
            }
        }

        :global(.mdc-form-field > label) {
            padding: 0;
            color: $valeo_black;
        }
    }

    td:nth-child(3) {
        @include fixed-width(0px);
    }

    td.primaryPortfolio {
        justify-content: center;
        @include fixed-width(152px);
    }
}

tr.portfolio:not(:first-child) {
    @include fixed-height(47px);
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $valeo_black_10;

    td {
        @include fixed-height(47px);
    }
}

tr.accounts {
    border-width: 0px;

    td {
        padding: 0;
        border-width: 0px;
    }

    :global(.mdc-data-table) {
        @include fixed-width(100%);
        border: 0;
        border-radius: 0;
    }

    :global(.mdc-data-table__table) {
        min-width: auto;
    }

    tr.account {
        border-width: 0px;

        td {
            min-height: 30px;
            height: fit-content;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        td.accountInfo {
            @include fixed-width(600px);
            padding-left: 96px;
            padding-right: 60px;
            font-family: "Outfit";
            font-weight: 300;
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            color: $valeo_black;
            white-space: pre;
        }

        td.loadingAccountValue {
            @include fixed-size(84px, 30px);

            .loading {
                @include fixed-size(84px, 12px);
            }
        }

        td.accountValue {
            @include fixed-width(84px);
            font-family: "Outfit";
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            text-align: right;
            color: $valeo_black;
        }
    }

    :global(.mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
        background-color: white;
    }
}

tr.loadingAccounts {
    @include fixed-height(12px);
    border-width: 0px;

    td {
        @include fixed-height(12px);
        padding: 0;
        border-width: 0px;

        .loading {
            @include fixed-size(100%, 12px);
        }
    }
}

.loading {
    color: $valeo_blue;

    :global(.mdc-linear-progress__bar-inner) {
        border-top: 12px solid;
    }
}