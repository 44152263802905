$pageWidth: 1392px;
$fabSize: 56px;
$fabMargin: 16px;
$tableMaxWidth: 1248px;
$tableMinWidth: 844px;
$clientProfileTopbarHeight: 127px;

$valeo_blue: #1C355C;
$gray: #807D79;
$dark_red: #B00020;

$valeo_black: #2A2928;
$valeo_black_90: #403E3C;
$valeo_black_60: #807D79;
$valeo_black_30: #C0BCB5;
$valeo_black_20: #D6D1CA;
$valeo_black_10: #DAD5CF;

$valeo_gray_130: #95928D;
$valeo_gray_100: #D6D1CA;
$valeo_gray_80: #DEDAD4;
$valeo_gray_60: #E6E3DF;
$valeo_gray_40: #EEECE9;
$valeo_gray_20: #F6F5F4;

$valeo_green_130: #4F7538;
$valeo_green_100: #71A850;
$valeo_green_80: #8DB972;
$valeo_green_60: #A9CA96;
$valeo_green_40: #C6DCB9;
$valeo_green_20: #E2EDDC;

$valeo_yellow_130: #968B19;
$valeo_yellow_100: #D7C724;
$valeo_yellow_80: #DFD24F;
$valeo_yellow_60: #E7DD7B;
$valeo_yellow_40: #EFE8A7;
$valeo_yellow_20: #F7F3D3;

$valeo_red_orange_130: #9F462F;
$valeo_red_orange_100: #E46444;
$valeo_red_orange_80: #E98369;
$valeo_red_orange_60: #EEA28E;
$valeo_red_orange_40: #F4C1B4;
$valeo_red_orange_20: #F9E0D9;

$valeo_blue_130: #224367;
$valeo_blue_100: #316094;
$valeo_blue_80: #5A7FA9;
$valeo_blue_60: #839FBE;
$valeo_blue_40: #ACBFD4;
$valeo_blue_20: #D5DFE9;

@mixin fixed-height($height) {
    height: $height;
    min-height: $height;
    max-height: $height;
}

@mixin fixed-width($width) {
    width: $width;
    min-width: $width;
    max-width: $width;
}

@mixin fixed-size($width, $height) {
    @include fixed-width($width);
    @include fixed-height($height);
}

@mixin table-row-transparent-hover {
    :global(.mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
        background-color: transparent;
    }
}

@mixin remove-table-border {
    border: 0;
    border-radius: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $valeo_black_10;

    tr:nth-of-type(1) {
        border-width: 0px;
    }
}

@mixin standard-table-row-borders {
    :global(.mdc-data-table__row) {
        border-top-color: $valeo_black_10;
    }
}

@mixin valeo-text-field-text($fontWeight, $fontSize, $lineHeight, $letterSpacing) {
    :global(.mdc-text-field__input),
    :global(.mdc-floating-label) {
        font-weight: $fontWeight;
        font-size: $fontSize;
        letter-spacing: $letterSpacing;
    }

    :global(.mdc-text-field__input),
    :global(.mdc-floating-label:not(.mdc-floating-label--float-above)) {
        line-height: $lineHeight;
    }
}

@mixin valeo-text-field-non-empty-notch($width) {
    :global(.mdc-notched-outline__notch) {
        @include fixed-width($width);
        border-top: 0px;
    }
}

@mixin valeo-text-field-table-row() {
    :global(.mdc-text-field--focused),
    :global(.mdc-ripple-upgraded--foreground-activation) {
        :global(.mdc-notched-outline__notch) {
            border-top: 2px solid;
        }
    }

    :global(.mdc-notched-outline__notch) {
        border-top: 1px solid;
    }

    :global(.mdc-floating-label--float-above) {
        display: none;
    }
}

@mixin valeo-select-size($width, $height) {
    &:global(.mdc-select) {
        @include fixed-size($width, $height);

        :global(.mdc-menu-surface) {
            @include fixed-width($width);
        }
    }
}

@mixin valeo-select-selected-text($fontWeight, $fontSize, $lineHeight, $letterSpacing) {
    :global(.mdc-select__selected-text),
    :global(.mdc-floating-label) {
        font-weight: $fontWeight;
        font-size: $fontSize;
        letter-spacing: $letterSpacing;
    }

    :global(.mdc-select__selected-text),
    :global(.mdc-floating-label:not(.mdc-floating-label--float-above)) {
        line-height: $lineHeight;
    }
}

@mixin valeo-select-option-text($fontWeight, $fontSize, $lineHeight, $letterSpacing) {
    :global(.mdc-list-item) {
        font-weight: $fontWeight;
        font-size: $fontSize;
        line-height: $lineHeight;
        letter-spacing: $letterSpacing;
    }
}

@mixin valeo-select-option-subheader-text($fontWeight, $fontSize, $lineHeight, $letterSpacing) {
    :global(.mdc-list-group__subheader) {
        font-weight: $fontWeight;
        font-size: $fontSize;
        line-height: $lineHeight;
        letter-spacing: $letterSpacing;
    }
}

@mixin valeo-select-uppercase() {
    :global(.mdc-select__selected-text),
    :global(.mdc-floating-label:not(.mdc-floating-label--float-above)),
    :global(.mdc-list-item) {
        text-transform: uppercase;
    }
}

@mixin valeo-select-non-empty-notch($width) {
    :global(.mdc-notched-outline__notch) {
        @include fixed-width($width);
        border-top: 0px;
    }
}

@mixin valeo-select-text-wrap() {
    :global(.mdc-list.mdc-menu__items) {
        :global(.mdc-list-item) {
            @include fixed-height(fit-content); // override ValeoSelect
            padding-top: 10px;
            padding-bottom: 10px;

            :global(.mdc-list-item__text) {
                white-space: normal;
            }
        }
    }
}

@mixin kebab-menu-hover() {
    :global(.mdc-ripple-upgraded--background-focused:not(.mdc-ripple-upgraded--foreground-activation).mdc-list-item__ripple) {

        &::before,
        &::after { // option with keyboard focus
            background-color: white;
            opacity: 0;
        }
    }

    :global(.mdc-ripple-upgraded--background-focused.mdc-list-item:hover) {
        background-color: rgba(0, 0, 0, 0.04); // option with keyboard focus (hover)
    }
}