@import "../../../variables";

.icon {
    pointer-events: auto; // Allow pointer events to come through (normally suppressed by icons within a TextField)
}

.table {
    table {
        border: 0;
    }
}

.headerRow {
    font-weight: 700;
}

.valuesRow {
    font-weight: 400;
}

.rangeColumn {
    @include fixed-width(96px);
    text-align: right;
}

.percentageColumn {
    padding-left: 8px;
    @include fixed-width(49px);
    text-align: center;
}
