@import "../../../variables.scss";

.accountInfo {
    width: 844px;
    margin-left: -44px;
    padding: 0 0 24px 0;

    .triangle {
        margin-left: 274px;
        width: 0px;
        margin-top: -18px;
        border-bottom: 18px solid $valeo_gray_20;
        border-right: 18px solid transparent;
        border-left: 18px solid transparent;
        border-top: 18px solid transparent;
    }

    .grayBackground {
        height: 137px;
        background-color: $valeo_gray_20;

        .label {
            padding-top: 12px;
            padding-left: 44px;
            color: $valeo_black;
        }

        .form {
            padding-top: 12px;
            padding-left: 44px;
            display: flex;

            .account {
                width: 158px;
                margin-right: 4px;
            }

            .owner {
                width: 192px;
                margin-right: 4px;
            }

            .custodian {
                width: 204px;
                margin-right: 4px;
            }

            .chooseType {
                width: 190px;

                .tooltipDiv {
                    width: 200px;
                }
            }

            .chooseTypeSelect {
                width: 100%;
            }
        }

        .helpText {
            font-size: 12px;
            color: $valeo_black;
            padding-left: 44px;
        }
    }
}