@import "../../../variables.scss";

.recommendedFundsList {
    padding-bottom: 24px;

    .elevation {
        height: fit-content;
        margin: 0 -44px;
        padding: 12px 44px;
        background-color: $valeo_gray_20;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);

        .label {
            @include fixed-height(21px);
            font-family: "Outfit";
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $valeo_black;
        }

        .assetClass {
            @include fixed-height(16px);
            margin-top: 4px;
            font-family: "Outfit";
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: $valeo_black;
        }
    }
}