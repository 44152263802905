@import "../../../variables.scss";

.checkbox {
    margin-right: 16px;
    font-size: 12px;
    color: $valeo_black;
}

.changedTargetsMessage {
    margin-right: 16px;
    font-size: 12px;
    color: $valeo_black;
}