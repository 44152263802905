@import "../../../../variables.scss";

.commentIcon.commentIcon.commentIcon {
    color: $valeo_black_60;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;

    width: min-content;
    display: flex;
    align-items: center;
}

.commentTooltip {
    @include fixed-width(120px);
}