@import "../../../../variables.scss";

.showOverlay {
    :global(.mdc-dialog__content) {
        overflow: hidden;
    }
}

.overlay {
    position: absolute;
    height: calc(100% - 99px);
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    z-index: 1000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Zilla Slab";
    font-weight: 500;
    font-size: 24px;
    color: $valeo_black;

    .visionButton {
        margin-top: 28px;
        color: $valeo_blue;
    }
}