@import "../../../../variables.scss";

.helpTextContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include fixed-size(1076px, 100%);

    .helpText {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-right: 24px;
        color: $valeo_black;
    }
}