@import "../../../variables.scss";

.recommendedFundsButton.recommendedFundsButton {
    color: $valeo_blue;
    margin-bottom: 16px;
}

.notAvailable {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .infoIcon {
        margin-right: 4px;
    }
}