@import "../../../../../variables.scss";

.overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    z-index: 1000;

    .cancelContainer {
        display: flex;
        justify-content: flex-end;
        @include fixed-height(48px);
        padding: 1px 48px 0 0;

        .cancelButton {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 48px;
            color: $valeo_black_60;
        }
    }

    .content {
        flex-grow: 1;
        padding: 0 44px 0 44px;

        .helperText {
            font-family: "Zilla Slab";
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin: 32px 0 36px 0;
            color: $valeo_black;
        }
    }
}