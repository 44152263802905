@use '@material/ripple';
@import "variables.scss";

body {
  margin: 0;
  font-family: 'Outfit';
  font-size: 16px;

  --mdc-typography-caption-font-family: 'Zilla Slab';
  --mdc-typography-caption-font-size: 16px;
  --mdc-typography-caption-font-weight: 500;

  --mdc-typography-font-family: 'Outfit';
  --mdc-typography-body1-font-weight: 500;
  --mdc-typography-body1-font-size: 16px;

  color: $valeo_black;
  --mdc-theme-text-primary-on-background: $valeo_black;
  --mdc-theme-primary: $valeo_blue;
  --mdc-theme-secondary: $valeo_blue;

  --mdc-typography-headline1-font-weight: 500;
  --mdc-typography-headline1-font-size: 20px;
  --mdc-typography-headline1-font-family: 'Zilla Slab';

  --mdc-typography-headline2-font-weight: 400;
  --mdc-typography-headline2-font-size: 24px;

  --mdc-typography-headline3-font-weight: 500;
  --mdc-typography-headline3-font-size: 20px;
  --mdc-typography-headline3-font-family: 'Outfit';

  --mdc-typography-headline4-font-weight: 500;
  --mdc-typography-headline4-font-size: 16px;
  --mdc-typography-headline4-font-family: 'Zilla Slab';

  --mdc-typography-headline5-font-weight: 700;
  --mdc-typography-headline5-font-size: 14px;
  --mdc-typography-headline5-font-family: 'Outfit';
  --mdc-typography-headline5-line-height: 17.64px;

  --mdc-typography-headline6-font-weight: 400;
  --mdc-typography-headline6-font-size: 14px;
  --mdc-typography-headline6-font-family: 'Outfit';
  --mdc-typography-headline6-line-height: 17.64px;

  .mdc-button {
    min-width: fit-content;
    padding: 10px 12px 10px 12px;

    .mdc-button__label {
      letter-spacing: 1px;
    }
  }

  .mdc-menu {
    min-width: fit-content;
  }

  .mdc-data-table__table-container {
    border-collapse: collapse;
    width: 100%;
  }

  .mdc-data-table__header-cell {
    text-transform: uppercase;
    font-size: 16px;
  }

  .mdc-data-table__table-container {
    overflow-x: visible;
  }

  .mdc-ripple-upgraded {
    @include ripple.states-opacities((focus: 0, hover: 0));

    .mdc-checkbox__ripple::before {
      display: none;
    }

    .mdc-button__ripple::before {
      display: none;
    }
  }

  .mdc-ripple-upgraded--background-focused {
    @include ripple.states-opacities((hover: 0));
  }

  .mdc-checkbox {

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      background-color: $valeo_blue;
      border-color: $valeo_blue;
    }

    .mdc-checkbox__ripple {
      display: none;
    }

    .mdc-checkbox__background {
      animation-duration: 0s;
    }

    .mdc-checkbox__checkmark-path {
      animation-duration: 0s;
    }

    &.mdc-checkbox--disabled {
      ~label {
        color: $valeo_black_20;
      }

      .mdc-checkbox__background.mdc-checkbox__background.mdc-checkbox__background.mdc-checkbox__background.mdc-checkbox__background {
        border-color: $valeo_black_20;
      }
    }

    &.mdc-checkbox--disabled.mdc-checkbox--selected {
      .mdc-checkbox__background.mdc-checkbox__background {
        background: $valeo_black_20;
      }
    }

  }

  .mdc-snackbar__surface {
    padding-right: 12px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  height: fit-content;
  position: relative;
  min-width: 100%;

  .EZDrawer {
    display: contents;
  }
}

.clickable {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

div.rmwc-tooltip {
  pointer-events: none;
}

div.rmwc-tooltip:not(.rmwc-tooltip-zoom-appear):not(.rmwc-tooltip-zoom-enter) {
  opacity: 1;
}

div.rmwc-tooltip-zoom-leave {
  animation-duration: 0s;
  animation-fill-mode: none;
}

.rmwc-tooltip-content div.rmwc-tooltip-arrow {
  border-bottom-color: $gray;
  border-top-color: $gray;
}

div.rmwc-tooltip-inner {
  padding: 8px 16px;
  background-color: $gray;
  color: white;
}

.invisible-fonts {
  visibility: hidden;
  height: 0px;

  .outfit {
    font-family: 'Outfit';
  }

  .zilla {
    font-family: 'Zilla Slab';
  }

  .weight-300 {
    font-weight: 300;
  }

  .weight-400 {
    font-weight: 400;
  }

  .weight-500 {
    font-weight: 500;
  }

  .weight-600 {
    font-weight: 600;
  }

  .weight-700 {
    font-weight: 700;
  }

  .material-icons {
    font-size: 0px;
  }
}

.rmwc-tooltip.sidebar-tooltip {
  position: fixed;
}

.test-mode {
  .rmwc-tooltip.sidebar-tooltip {
    position: absolute;
  }
}