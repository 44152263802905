@import "../../../variables.scss";

.modalContent {
    padding-left: 44px;

    .accountInfo {
        padding-top: 24px;
        padding-bottom: 9px;
        color: $valeo_black;
    }

    .form {
        display: flex;

        .account {
            width: 158px;
            margin-right: 4px;
        }

        .owner {
            width: 192px;
            margin-right: 4px;
        }

        .custodian {
            width: 204px;
            margin-right: 4px;
        }

        .typeSelect {
            width: 190px;
        }
    }

    .optionalText {
        @include fixed-height(24px);
    }

    .helpText {
        color: $valeo_black;
        font-size: 12px;
        font-weight: 400;
    }
}

.saveButton {
    &:global(.mdc-dialog__button.mdc-button) {
        margin-top: auto;
    }
}

.footerFiller {
    flex-grow: 1;
}

.duplicateAccount {
    margin-right: 16px;
    color: $valeo_black;
    font-size: 12px;
    letter-spacing: 0.4px;
}