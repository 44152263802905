@import '../../variables.scss';

.topbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: $clientProfileTopbarHeight;

    .hamburger {
        margin-left: 8px;
        margin-top: 8px;
        color: $valeo_blue;
    }

    .vtap {
        font-family: "Zilla Slab";
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.15px;
        color: $valeo_blue;
        margin-top: 30px;
        text-align: center;

        .logo {
            height: 50px;
            width: 50px;
        }
    }

    .userContainer {
        margin-top: 19px;
        margin-right: 44px;
        justify-self: end;
        font-size: 20px;
        letter-spacing: 0.15px;
        color: $valeo_blue;

        .user {
            display: flex;

            .userIcon {
                margin-right: 12px;
            }

            .userName {
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}