@import "../../variables.scss";

.textField:global(.mdc-text-field):global(.mdc-text-field--disabled) {
    background-color: white;
    border-radius: 4px;

    :global(.mdc-text-field__input),
    :global(.mdc-text-field__affix) {
        color: $valeo_black_30; // non-empty label
    }
    :global(.mdc-notched-outline) :global(.mdc-notched-outline__leading),:global(.mdc-notched-outline__trailing),:global(.mdc-notched-outline__notch) {
        border-color: $valeo_black_30;
    }
    :global(.mdc-select__selected-text) {
        color: $valeo_black_30;
    }
    :global(.mdc-floating-label):global(.mdc-floating-label--float-above) {
        color: $valeo_black_30;
    }
}

.textField:global(.mdc-text-field:not(.mdc-text-field--invalid)):not(:global(.mdc-text-field--disabled)) {
    border-radius: 4px;

    :global(.mdc-text-field__input) {
        color: $valeo_black; // non-empty label, text cursor
    }

    :global(.mdc-text-field__affix) {
        color: $valeo_black_60;
    }

    :global(.mdc-floating-label) {
        color: $valeo_black_60; // placeholder label (non-floating), floating label (inactive)
    }

    :global(.mdc-notched-outline__leading),
    :global(.mdc-notched-outline__notch),
    :global(.mdc-notched-outline__trailing) {
        border-color: $valeo_black_60; // border (inactive, no hover)
    }

    &:hover {
        :global(.mdc-notched-outline) {
            :global(.mdc-notched-outline__leading),
            :global(.mdc-notched-outline__notch),
            :global(.mdc-notched-outline__trailing) {
                border-color: $valeo_black_60; // border (inactive, hover)
            }
        }
    }

    &:global(.mdc-text-field--focused) {
        color: $valeo_blue; // floating label (active)
    
        :global(.mdc-notched-outline__leading),
        :global(.mdc-notched-outline__notch),
        :global(.mdc-notched-outline__trailing) {
            border-color: $valeo_blue; // border (active, no hover)
        }
    
        &:hover {
            :global(.mdc-notched-outline) {
                :global(.mdc-notched-outline__leading),
                :global(.mdc-notched-outline__notch),
                :global(.mdc-notched-outline__trailing) {
                    border-color: $valeo_blue; // border (active, hover)
                }
            }
        }
    }

    span:before {
        background-color: white;
    }

    :global(.mdc-line-ripple) {
        visibility: hidden;
    }
}

.textField {
    background-color: white;

    :global(.mdc-text-field__affix) {
        font-size: inherit;
    }
}