@import "../../../variables.scss";

.positionName {
    min-height: 52px;
    padding-bottom: 24px;

    .securityName {
        display: flex;
        margin-top: 8px;
        padding-left: 16px;

        .securityNameText {
            padding-top: 4px;
            font-family: "Outfit";
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.44px;
            color: $valeo_black;
        }

        .recommendedFundIcon {
            margin-left: 10px
        }
    }

    .enterNameField {
        @include fixed-size(344px, 56px);
        @include valeo-text-field-text(400, 16px, 40px, 0.44px);
        margin-top: 12px;
    }

    button.enterNameButton {
        @include fixed-size(fit-content, 36px);
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        color: $valeo_blue;
        text-transform: uppercase;
    }

    .noSecurityResults {
        padding: 12px 0 10px 16px;
        font-family: "Outfit";
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.44px;
        color: $valeo_black;
    }
}