@import "../../../variables.scss";

.percentage_value {
    margin-right: 50px;
}

.red {
    color: $valeo_red_orange_130;
}

.green {
    color: $valeo_green_130;
}

.blue {
    color: $valeo_blue_130;
}

.yellow {
    color: $valeo_yellow_130;
}