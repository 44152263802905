@import "../../../../variables.scss";

.securityMenu {
    .menu {
        @include kebab-menu-hover();
        translate: 27px;

        &.widestOptionIsEditName {
            translate: 105px;
        }
    }

    .menu.sidebarOpen {
        translate: -84px;

        &.widestOptionIsEditName {
            translate: -6px;
        }
    }

    .menuItem {
        padding-right: 20px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.25px;
        color: $valeo_black_90;
        white-space: nowrap;

        &.red {
            color: $dark_red;
        }
    }

    .visible.visible.visible {
        display: flex;
    }
}