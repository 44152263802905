@import "../../../../variables.scss";

.tooltip {
    @include fixed-width(160px);

    :global(.rmwc-tooltip-content) {
        padding-top: 10px;
    }

    :global(.rmwc-tooltip-content div.rmwc-tooltip-arrow) {
        border-width: 0 9px 9px;
        top: 2px;
        left: 10px;
    }
}

.value {
    text-align: right;
    padding-top: 8px;
}

.icon {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}
