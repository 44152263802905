@import "../../variables.scss";

.fabContainer {
  @include fixed-width($fabSize);
  margin-right: $fabMargin;

  button.fab {
    position: fixed;
    top: calc(100vh - $fabSize - $fabMargin);
    @include fixed-size($fabSize, $fabSize);
    padding: 0;
    border-radius: $fabSize;
    background-color: $valeo_blue;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.24);
    color: white;
    z-index: 2;
  }
}

.assetTableContainer {
  display: flex;
}

.assetClassTables {
  width: min-content;
}

.snackbar :global(.mdc-snackbar__surface) {
  @include fixed-width($pageWidth);
  background-color: white;
  border: 2px solid $valeo_blue;
  :global(.mdc-snackbar__label) {
    color: $valeo_blue;
  }

  button {
    color: $valeo_blue;
  }
}