@import "../../../../../../variables.scss";

.possessionRow {
    height: 61px;

    .accountInfo {
        padding-left: 44px;
        font-size: 16px;
        text-transform: uppercase;

        .account {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
        }

        .owner {
            font-weight: 300;
        }
    }

    .accountNumber {
        font-size: 16px;
        padding-left: 32px;
        padding-right: 4px;
    }

    .textField {
        padding-left: 0px;
        padding-right: 4px;
        @include valeo-text-field-table-row();
    }

    .chooseType {
        padding-right: 39px;
        padding-left: 0px;
        overflow: visible;
        @include valeo-select-text-wrap();
    }

    .tooltipDiv {
        margin-left: auto;
        margin-right: auto;
    }
}