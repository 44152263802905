@import "../../../../../variables.scss";

.table {
    width: 100%;
    padding: 0px;
    @include remove-table-border();

    :global(.mdc-data-table__row) {
        height: 61px;
    }

    :global(.mdc-data-table__cell),
    :global(.mdc-data-table__header-cell) {
        padding-left: 0;
    }

    .holdingName {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.44px;
        color: $valeo_black;
        text-transform: uppercase;
    }

    .column1 {
        display: inline-flex;
        align-items: center;
        @include fixed-width(498px);
        min-height: 61px;
        padding: 0 0 0 44px;
    }

    .column2 {
        display: inline-flex;
        align-items: center;
        @include fixed-width(75px);
        padding: 0 0 0 8px;
        height: fit-content;
    }

    .column3 {
        display: inline-flex;
        align-items: center;
        @include fixed-width(94px);
        padding: 0 0 0 8px;
        height: fit-content;
    }

    .alignVerticalCenter {
        display: flex;
        align-items: center;
    }

    .bgTransparent:hover {
        background-color: transparent;
    }
}