@import "../../../variables.scss";

.elevation {
    position: relative;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    @include fixed-height(129px);

    .stepper {
        margin-top: 33px;
        margin-left: 134px;
    }

    .title {
        margin-top: 24px;
        margin-left: 44px;
    }
}