@import "../../../../variables.scss";

.menu {
    @include kebab-menu-hover();
    translate: 76px;
    width: max-content;
}

.menu.blended {
    translate: 34px;
}

.menu.sidebarOpen {
    translate: -35px;
}

.menu.sidebarOpen.blended {
    translate: -77px;
}

.menuItem {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
    color: $valeo_black_90;
    line-height: 36px;
    min-width: -webkit-fill-available;
}

.deleteMenuItem {
    color: $dark_red;
}