@import "../../../variables.scss";

.leftMargin16 {
    margin-left: 16px;
}

.select {
    @include valeo-select-size(340px, 56px);
    @include valeo-select-selected-text(400, 16px, 40px, 0.44px);
    @include valeo-select-option-text(400, 16px, 40px, 0.44px);

    &.nonEmptyBase {
        @include valeo-select-non-empty-notch(138px);
    }

    .noBaseOption {
        font-weight: 600;
    }
}

.customName {
    @include fixed-width(340px);
}

.nonEmptyName {
    @include valeo-select-non-empty-notch(97px);
}